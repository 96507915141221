import './App.css';
import Header from './pages/Header';
import Banner from './pages/Banner';
import News from './pages/News';
import NavBar from './pages/NavBar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MobileDynamicBanner from './pages/MobileDynamicBanner';
import HomePage from './pages/HomePage';
import Footer from './pages/Footer';
import Course from './pages/Course';
import CourseDetails, { courseDetailForRoute } from './data/CourseDetails';
import NavigationMenu from './data/NavigationMenu';
import Contact from './pages/Contact';
import Article from './pages/Article';
import ArticleDetails from './data/ArticleDetails';
import JobOpenings from './pages/JobOpenings';
import JobDetails from './data/JobDetails';


function App() {
  return (
    <div>
      <Router>
        <div className="dev-container container">
          <Header />
          <Banner />
          <News />
          <NavBar />
          <MobileDynamicBanner />
          <Switch>
            <Route path='/' exact>
              <HomePage courseDetail={courseDetailForRoute} />
            </Route>
            {
              courseDetailForRoute.map((course, index) => {
                return <Route key={index} path={`/${course.url}/:courseIndex/:courseId`} exact>
                  <Course courseDetails={CourseDetails} navigationMenu={NavigationMenu} courseDetailForRoute={courseDetailForRoute} />
                </Route>
              })
            }
            <Route path='/contact' exact>
              <Contact navigationMenu={NavigationMenu} courseDetail={courseDetailForRoute} />
            </Route>

            <Route path='/article' exact>
              <Article articleDetails={ArticleDetails} />
            </Route>

            <Route path='/job-openings' exact>
              <JobOpenings jobDetails={JobDetails} />
            </Route>

          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
