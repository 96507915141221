const JobDetails = [
    {
        "image":"job-opening.png",
        "title":"Mphasis Hiring Java Developers",
        "about_company":"Mphasis is a leading IT solutions provider, offering Applications, Business Process Outsourcing (BPO) and Infrastructure services globally through a combination of technology knowhow, domain and process expertise.</br></br>Mphasis Limited (then, Mphasis BFL Limited) was formed in June 2000 after the merger of the US-based IT consulting company Mphasis Corporation (founded in 1998) and the Indian IT services company BFL Software Limited (founded in 1993).Over the years we have left an indelible impression in the IT solutions domain with an impressive clientele and an extensive global presence. The accolades we have been garnering can be attributed to our undeterred focus in delivering quality solutions across verticals that meet the challenging requirements of our esteemed customers. Our integrated solutions offering is aimed at creating value for our customers, helping them in improving their business processes with minimum hassles and capital outlays. The perfect blend of technical excellence, business performance monitoring, business intelligence and customer experience management is what makes us endearing to our clients.",
        "JobTitle": "Java Developers",
        "ExperienceLevel":"4 - 9 Years",
        "WorkLocation":"Chennai",
        "job_description":"<li>Excellent Java programming skills</li></br><li>Experienced in Java based software development for enterprise applications</li></br><li>Good in micro services & Spring-boot</li></br><li>Work Location: Chennai DLF</li></br><li>Notice period: 30days</li></br><li>Experience 4 to 9 Years</li>",
        "Email": "bidhusree.padhi@mphasis.com"
                 
    },
     {
        "image":"job-opening.png",
        "title":"Kumaran Systems Java Full Stack Developer Opportunity",
        "about_company":"We are a rapidly growing NASSCOM 2009-listed IT-enabling company with footprint in four countries. For two decades now, we have provided our global clientele with a range of high-end IT services including migration support, system integration, and infrastructure management solutions. Equipped with two development centers located at Chennai, Kumaran Systems can be that one-stop-shop for every IT solution that your business demands. Kumaran has been ranked among the Top 500 global software companies (16th Annual Ranking by Software Magazine) and also among the Top 200 Indian software companies (3rd Annual Ranking by Data quest).",
        "JobTitle": "Java Full Stack Developer",
        "ExperienceLevel":"4-7 years",
        "WorkLocation":"Chennai",
        "job_description":"<li>Java, J2ee, Spring boot/ Spring Frameworks, Hibernate, Restful</li></br><li>Good hands-on in JQuery, Java script/ HTML</li></br><li>Availability: Within 15 Days to 20 Days</li></br><li>Strong communication and explaining problem/solution</li>",
        "Email": "sasirega.neduenchezhian@kumaran.com"
                   
    },
     {
        "image":"job-opening.png",
        "title":"Java Developer (java, Spring, Restful) in Capgemini",
        "about_company":"Capgemini Technology Services India Limited</br>A global leader in consulting, technology services and digital transformation, Capgemini is at the forefront of innovation to address the entire breadth of clients opportunities in the evolving world of cloud, digital and platforms. Building on its strong 50-year heritage and deep industry-specific expertise, Capgemini enables organizations to realize their business ambitions through an array of services from strategy to operations. Capgemini is driven by the conviction that the business value of technology comes from and through people. It is a multicultural company of over 200,000 team members in more than 40 countries. The Group reported 2018 global revenues of EUR 13.2 billion. Visit us at www.capgemini.com. People matter, Results count.</br>Capgemini in India comprises over 100,000 team members working across 12 locations Bangalore, Bhubaneswar, Chennai, Gandhinagar, Gurugram, Hyderabad, Kolkata, Mumbai, Noida, Pune, Salem and Tiruchirappalli. Learn more about Capgemini in India at www.capgemini.com/in-en.",
        "JobTitle": "Java Developers",
        "ExperienceLevel":"3-6 years",
        "WorkLocation":"Chennai",
        "job_description":"<li>Excellent knowledge of Java and Spring</li></br><li>Candidate should have working knowledge on web services</li></br><li>Should have worked in distributed agile model and continuous integration</li></br><li>Should have knowledge on designing and implementation of REST Web services</li></br><li>Strong experience with REST API and web services</li></br><li>Should be efficient with Java J2EE and related technologies</li></br><li>Strong Java and spring</li></br><li>Strong RESTFUL web service experience</li></br><li>Primary Skill: Java, spring, spring boot & RESTFUL Web services</li>",
        "Email": "geeta.kshirsagar@capgemini.com"
                   
    },
    {
        "image":"job-opening.png",
        "title":"UI PATH RPA Developer",
        "about_company":"Newage is a worldwide leader in using technology to simplify logistics. Over 10 years in the industry, global client base, and leadership with multiple years of technology and domain expertise. We build software products, offer business process management and consulting services. With flexible software deployment options, we let you run your business in the cloud, on-site or a combination of both. Through our Business process services, Newage delivers quantifiable value to our Freight & Logistics customers that enable them to reduce operational costs, grow business, reduce risk, and quickly react to market dynamics. Newage is built by Freight & Logistics industry experts.",
        "JobTitle": "RPA Developer",
        "ExperienceLevel":"1-4 years",
        "WorkLocation":"Chennai",
        "job_description":"<li>Develop RPA solutions to meet the project/program objectives</li></br><li>Design, build and configure applications to meet business process and application requirements</li></br><li>Mandatory Technical Skills Developer with IT skills (C/C++/C#/ VB / Java / .net)</li></br><li>Desirable Technical Skills Experience in RPA development or in any product development projects would be highly desirable</li></br><li>Domain Exposure/ExpertiseRobotic Process Automation (tools like UiPath, Blue Prism, Work Fusion, OpenSpan and Automation Anywhere etc.)</li></br><li>RPA developer involve in 3 phases. That are Opportunity Identification, BRD Creation and Development, Testing and Deployment</li></br><li>In this structure, RPA Developers go through the business requirements with Operations and Business Analysts over process walk through (BRD is written by BAs along with Operations)</li></br><li>Testing (Integration, Unit and UAT Testing)</li></br><li>Production Deployment</li></br><li>RPA Developers are also engaged in Bot Production support and maintenance work - change management or resolving bugs/exceptions</li><li>Bot Production Support and Maintenance can be separate team of RPA developers or they can cross utilized for both development and maintenance depending on the project requirements</li></br>",
        "Email": "training@devtechnos.in"
                   
    }
]

export default JobDetails;