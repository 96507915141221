import { Fragment } from "react";
import { useEffect, useRef, useState } from 'react';

const News = () => {
    const ElementRef = useRef(null);
    const [newsSlider, setNewsSlider] = useState(0);
    const [couter, setCouter] = useState(1);
    let Elementcoun = 0;

    useEffect(() => {
        const Elementcount = ElementRef.current.childNodes.length;
        Elementcoun = Elementcount;
        if (couter > Elementcount) {
            setNewsSlider(() => 0);
            setCouter(() => 1);
        }
    }, [couter])

    const nextButtonHandler = () => {
        setNewsSlider((prevState) => prevState - 22);
        setCouter((prevState) => prevState + 1);

    }

    const prevButtonHandler = () => {
        if (couter === 1) {
            setNewsSlider((prevState) => prevState - (22 * (Elementcoun - 1)));
            setCouter(() => Elementcoun);
        } else {
            setNewsSlider((prevState) => prevState + 22);
            setCouter((prevState) => prevState - 1);
        }
    }

    return <Fragment>
        {/* <!--News Begin--> */}
        <div className="row for-desktop">
            <div className="col-md-12">
                <div className="news">
                    <div className="row">
                        <div id="jsn-content-scrolling">
                            <div className="moduletable">
                                <div id="gkHighlighterGK4-gkHighlight_179" className="gkHighlighterGK4">
                                    <div className="gkHighlighterInterface newsInterface">
                                        <span className="text">Dev News</span>
                                        <div>
                                            <button className="nextprev prev" onClick={prevButtonHandler}>
                                                <i className="glyphicon glyphicon-chevron-left"></i>
                                            </button>
                                            <button className="nextprev next" onClick={nextButtonHandler}>
                                                <i className="glyphicon glyphicon-chevron-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="newsWrapper" ref={ElementRef}>
                                        <div className="newsLine" style={{ top: `${newsSlider}px` }}><span>: New Batches Start on this weekend, for all Courses. For more details Contact
                                            Us  +91-8438810156.
                                        </span>
                                        </div>

                                        <div className="newsLine" style={{ top: `${newsSlider}px` }}><span>: We Offer Online Training ,
                                            Classroom Training for RPA tools, Java/J2EE ,DotNet, Oracle and PHP to
                                            Development</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!--News End--> */}
    </Fragment>
}

export default News;