import { useEffect, useRef, memo } from "react";
import RequestForm from "../components/RequestForm";
//import Page from '../rolling/roll.html';
// const htmlDoc = {__html: Page};
import Roll from '../media/videos/roll-new.mp4';
import { useHistory } from "react-router-dom";

const HomePage = (props) => {
    const navigate = useHistory();
    const courseDetail = props.courseDetail;
    const homePage = useRef(null);

    useEffect(() => {
        //const y = homePage.current.getBoundingClientRect().top + window.pageYOffset - 57;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return <div ref={homePage}>
        {/* <!--HomePage Begin--> */}
        <div className="homepage row">
            <div className="col-md-12 col-xs-12">
                <div className="row section-1">
                    <div className="col-sm-6 col-md-4 col-xs-12 home-sec-1-pad-l-0">
                        <div className="left">
                            <h4 className="middle-font black-colo">Dev Technology: The leading software training institute
                                in Chennai</h4>
                            <p>Dev Technology, an institute for software courses was founded in 2009 with a vision to
                                provide world-class training in all software technology courses. Today, we stand proud
                                as an institute which has trained thousands of students and guided them to successful
                                careers in software technology solutions.

                                The world of Information Technology is ever growing and provides great opportunities for
                                placements. As the number one IT training institute in Chennai, we are known for
                                providing a practical exposure to enhance the understanding and learning capabilities of
                                our students.</p>
                            <h4 className="middle-font black-colo">The complete software technology training</h4>
                            <p>Our software training courses in Java and other software technologies are structured to
                                provide our students with the complete experience, be it theoretical, practical or real
                                time. The trainers have more than 8 years of experience in the field and have worked in
                                MNC’s giving you the opportunity to learn and grow in software technology with a
                                “hands-on” guidance.</p>
                            <p>Experts in Java and RPA courses, we have created a niche for ourselves in the industry of
                                software technology courses both online and offline. Providing our students with lab
                                sessions, real time application experiences, and expert guidance is our mission and
                                we’ve completed it successfully.</p>
                            <h4 className="middle-font black-colo">Get globally certified in software technology</h4>
                            <p className="justify">Our software technology courses are structured according to global
                                standards, giving you the opportunity to receive global certification. We provide
                                complete support to our students and help them clear global certification exams in
                                software technology with ease.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-xs-12">
                        <div className="middle">
                            <h4 className="middle-font black-colo">Boost your software technology career with Dev Technology
                            </h4>
                            <div className="justify">Being Chennai’s top institute for software technology, we ensure your
                                career progresses in the right direction. Here is why choosing us for software
                                technology courses is the right decision:
                                <ul>
                                    <li>Globally certified trainers</li>
                                    <li>100% placement guarantee for software technology courses in Java, RPA, Oracle and
                                        Python</li>
                                    <li>Learn from anywhere at anytime with our online software technology courses</li>
                                    <li>Complete training for global certification in software technology</li>
                                    <li>Wholesome training designed to provide real time exposure</li>
                                    <li>High quality software training from expert professionals with more than 8 years of
                                        experience</li>
                                    <li>Complete placement assistance in every course</li>
                                    <li>Free demo classes available at your convenience</li>
                                </ul>
                                The privilege of choice is valued highly at our institute of software technology in Chennai.
                                We provide every individual with a chance to experience our world class training methods
                                with a free demo class which is organized at your convenience.
                                Our 80% practical and 20% theory approach has benefitted all our students in obtaining jobs
                                in software technology. Our “anywhere, anytime” policy allows all over the world to access
                                our courses and benefit from them. Merging this with expert software technology training,
                                our online classes have become a knowledge fountain which gives you the push your career
                                needs.
                            </div>
                            <h4 className="middle-font black-colo">IT Placements and online training in Chennai with experts
                            </h4>
                            <p className="justify">We guarantee placements for courses like Java, Oracle, Python and RPA.
                                Our assistance has helped over hundred students start a successful career in software
                                technology.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                        <div className="row">
                            <video width="330" autoPlay loop muted 
                                style={{
                                    cursor:'pointer'
                                }}
                                onClick={() =>  navigate.push("/contact")}
                                onMouseOver={((event) => event.target.pause())}
                                onMouseLeave={((event) => event.target.play())} >
                                <source src={Roll} type="video/mp4" />
                            </video>

                        </div>

                        <div className="row">
                            <div className="req-btn col-md-12 col-sm-6 pr-0">
                                <div className="message-session-home">
                                    <h5 className="requestForDemoClass">Request For Demo Class</h5>
                                    <RequestForm courseDetail={courseDetail} />
                                </div>
                            </div>

                            <div className="testimonial col-md-12 col-sm-6" id="testimonialPage">

                            </div>
                        </div>
                    </div>

                </div>

                <div className="row section-2">
                    <div className="col-md-12 col-xs-12 home-sec-1-pad-l-0">
                        <ul className="nav nav-tabs dev-tab responsive-tabs">
                            <li className="active"><a data-toggle="tab" href="#home">About Us</a></li>
                            <li><a data-toggle="tab" href="#menu1">Classroom Training</a></li>
                            <li><a data-toggle="tab" href="#menu2">Online Training</a></li>
                            <li><a data-toggle="tab" href="#menu3">Corporate Training</a></li>
                        </ul>

                        <div className="dev-tab-content tab-content">
                            <div id="home" className="tab-pane fade in active tabPanel">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>About Us</h3>
                                    </div>
                                    <div className="col-md-3 padd-r-0">
                                        <img className="img-responsive" src="./media/images/img-consultancy.png" />
                                    </div>
                                    <div className="col-md-9 mar-l padd-l-0">
                                        <p>A versatile software training institute in Chennai, Dev Technology Solutions
                                            provides wholesome software technology courses which cover not only
                                            theoretical but practical knowledge with real time implementation.
                                        </p>
                                        <p>Coupled with one-on-one coaching, online training modules are also provided.
                                            Providing global accessibility allows us to deliver quality software
                                            technology training to every corner of the world. Our “anywhere, anytime”
                                            policy gives you the best training in Java, RPA and other software
                                            technology at your convenience.
                                        </p>
                                        <p>We even provide corporate training to enterprises looking to develop
                                            themselves in the field of software technology. A vast array of courses is
                                            taught at our training center in Chennai by experts who have a minimum of 8
                                            years of experience in this field.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="menu1" className="tab-pane fade tabPanel">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Classroom Training</h3>
                                    </div>
                                    <div className="col-md-3 padd-r-0">
                                        <img className="img-responsive" src="./media/images/img-classroom.png" />
                                    </div>
                                    <div className="col-md-9 mar-l padd-l-0">
                                        <p>Learn skills which enhance your career with experts in a personalized
                                            training environment. Immersive learning experiences combined with real time
                                            implementation projects ensure that your knowledge base expands not just
                                            theoretically but practically as well.</p>
                                        <p>A 100% placement guarantee for courses like Java, RPA and oracle and complete
                                            placement assistance for other courses ensures you get a great return on
                                            your investment. A secure future in software technology awaits you with our
                                            carefully curated courses.
                                        </p>
                                        <p>Every course is structured in such a way that it covers understanding,
                                            planning, management and implementation of the language or technology in
                                            every possible way. Taught by experts, our students are able to understand
                                            the course better by relating to actual experiences.
                                        </p>
                                        <p>Built with the vision of helping freshers achieve a strong foundation for
                                            their career in software technology and assisting professionals in
                                            advancement, our software technology training is flexible and detailed to
                                            ensure that every individual gets what they need and more from our course.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div id="menu2" className="tab-pane fade tabPanel">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Online Training</h3>
                                    </div>
                                    <div className="col-md-3 padd-r-0">
                                        <img className="img-responsive" src="./media/images/img-online.png" />
                                    </div>
                                    <div className="col-md-9 mar-l padd-l-0">
                                        <p>We believe everyone has the right to receive expert software technology
                                            training. Our online software training course can be accessed by anyone,
                                            anywhere and at any time. This student friendly learning module has helped
                                            thousands not only use their knowledge to get placed but also achieve global
                                            certification.
                                        </p>
                                        <p>We provide complete assistance with placement for all our courses. Our expert
                                            team can even help you get globally certified in the field you have chosen.
                                            The trainers deliver the course material via online portals and enable our
                                            students to understand all aspects of the course with ease.
                                        </p>
                                        <p>Our software training course has modules which ensure practical knowledge is
                                            delivered. Projects and implementation exercises help the student experience
                                            how the technology is used in real time. Our holistic training for courses
                                            like Java, Oracle and RPA along with many other software courses is
                                            delivered by trainers with more than 8 years of training.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="menu3" className="tab-pane fade tabPanel">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Corporate Training</h3>
                                    </div>
                                    <div className="col-md-3 padd-r-0">
                                        <img className="img-responsive" src="./media/images/img-corporate.png" />
                                    </div>
                                    <div className="col-md-9 mar-l padd-l-0">
                                        <p>The field of software technology is ever growing. Corporates need to be up to
                                            date on every new feature to keep the competition at bay. This is where Dev
                                            Technology’s corporate training for software technology can help you.
                                        </p>
                                        <p>We deliver the highest standards of training via experts who have at least 8
                                            years of experience. They are well equipped to provide a varied structure of
                                            the selected course and help corporates learn and implement software
                                            technology in the best way.
                                        </p>
                                        <p>We have worked with multiple MNC’s and renowned corporates in Chennai. Our
                                            training has helped these companies achieve an upper hand against their
                                            competition. You can also avail corporate training in software technology by
                                            contacting us.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!--HomePage End--> */}
    </div>
}

export default memo(HomePage);