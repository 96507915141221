import { Fragment, useEffect, useRef, memo } from "react"
import { NavLink } from '../components/NavbarElements';
import RequestForm from "../components/RequestForm";

const Contact = (props) => {
    const navItems = props.navigationMenu;
    const courseDetail = props.courseDetail;
    const mar = useRef();
    const contactPage = useRef(null);

    useEffect(() => {
        const y = contactPage.current.getBoundingClientRect().top + window.pageYOffset - 57;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }, []);

    return <div ref={contactPage}>
        {/* <!--Contact Us Page Begins--> */}
        <div id="contactUsPage" className="contactus-page row">
            <div className="col-md-4 col-sm-12">
                <div className="row">
                    <div className="col-md-12"></div>
                    <div className="col-md-12">
                        <div className="courseboxmid">
                            <div className="coursehead ora-color">
                                Featured Courses
                            </div>
                            <div className="featured">
                                <marquee ref={mar} className="marquee" direction="up" scrollamount="2" behavior="scroll">
                                    <ul id="contactusCourseList">
                                        {
                                            navItems.map((navItem, index) => {
                                                return <Fragment key={index}>{
                                                    'dropdown' === navItem.type && navItem.course.map((c, inx) => {
                                                        return <li key={inx}>
                                                            <NavLink to={'/' + c.url + '/' + index + '/' + inx} className="courses-list">{c.courseName}</NavLink>
                                                        </li>
                                                    })
                                                }
                                                </Fragment>
                                            })
                                        }

                                    </ul>
                                </marquee>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-sm-6">
                <div className="row">
                    <div className="col-md-12 section-c-1">
                        <h3 className="heading ora-color">Dev Technology Solutions</h3>
                        <div class="address">
                            <p>No:T4, RBT Mall, Ground Floor,</p>
                            <p>Meyyanur Bypass Road,</p>
                            <p>Salem-636004.</p>
                            <p>Landmark: Near ARRS Multiplex</p>
                            <p>Phone(M): <b>+91-84388 10156</b></p>
                            <p>Phone(L): <b>+91-74021 01006</b></p>
                            <p>Email Address: <a href="mailto:training@devtechnos.in">training@devtechnos.in</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-sm-4">
                <div className="req-btn col-md-12 col-sm-6 pfull-0 mt-15">
                    <div className="message-session-home">
                        <h5 className="requestForDemoClass">Request For Demo Class</h5>
                        {/* Form Begins */}
                        <RequestForm courseDetail={courseDetail} />
                        {/* Form End */}
                    </div>
                </div>
            </div>

            <div className="col-md-12 col-sm-6 col-xs-12 maps">
                <div className="row">
                    <div className="col-md-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.3769834372765!2d78.13354007505521!3d11.667672988540563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf041ac1e70f1%3A0x55fb3c452bb7572c!2sRBT%20Mall!5e0!3m2!1sen!2sin!4v1739107655316!5m2!1sen!2sin" 
                            width="100%" height="300" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0">
                        </iframe>
                    </div>
                </div>
            </div>

        </div>
        {/* <!--Contact Us Page End-- > */}
    </div>
}

export default memo(Contact);